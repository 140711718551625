<template>
  <div class="a-payment-container">
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div>
      <el-form ref="form" :model="form" :inline="true" label-width="80px">
        <el-form-item label="时间">
          <el-date-picker
            v-model="form.time"
            placeholder="请选择时间"
            type="month"
            :picker-options="{ disabledDate }"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="服务商">
          <el-select v-model="form.manufacturerId" placeholder="请选择服务商">
            <el-option label="不限" :value="undefined"></el-option>
            <template v-for="(item, index) in serviceNames">
              <el-option
                :label="item.displayName"
                :value="item.id"
                :key="index"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleFilter">筛选</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      id="payList"
      class="a-table"
      :data="tableData"
      @row-click="handleRowClick"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="订单号" prop="orderNo">
        <template slot-scope="scope">
          <span style="cursor: pointer">{{ scope.row.orderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="工艺" prop="technology" />
      <el-table-column label="数量" prop="technology">
        <template slot-scope="scope">
          {{ scope.row.models ? scope.row.models.length : 0 }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="state">
        <template slot-scope="scope">
          <el-tag size="small" :type="types[scope.row.state]">{{
            scope.row.state ? status[scope.row.state] : ""
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建日期" prop="createTime" />
    </el-table>
    <!-- 分页 -->
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.currentPage"
        :page-sizes="pageInfo.pageSizes"
        :page-size="pageInfo.pageSize"
        :layout="pageInfo.layout"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
    <!-- <div class="a-download">
      <el-button @click="handleDownload" type="primary">下载</el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentMonth: new Date(),
      form: {
        time: new Date(),
        manufacturerId: undefined,
      },
      serviceNames: [],
      status: this.$store.state.status,
      types: this.$store.state.types,
      customerId: localStorage.getItem("customerId"),
      /**分页 */
      pageInfo: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        layout: "total, sizes, prev, pager, next, jumper",
        currentPage: 1,
        total: 1,
      },
      loading: true,
    };
  },
  methods: {
    getOrderList() {
      const page = {
        pageIndex: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
      };
      const customerId = localStorage.getItem("customerId");
      const time = {
        year: this.form.time.getFullYear(),
        month: this.form.time.getMonth() + 1,
      };
      this.$axios
        .getOrderList({
          ...page,
          customerId,
          manufacturerId: this.form.manufacturerId,
          ...time,
          // state: "Completed",
        })
        .then((res) => {
          if (!res.error) {
            this.pageInfo.total = res.totalCount;
            res.data.map((customer) => {
              return (customer.createTime = this.$utils.dateToString(
                customer.createTime
              ));
            });
            this.tableData = res.data;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading = false;
        });
    },
    getServiceNames() {
      this.$axios
        .getServiceNames({ customerId: this.customerId })
        .then((res) => {
          if (!res.error) {
            console.log(res);
            this.serviceNames = res;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
    },
    disabledDate(date) {
      return date > new Date();
    },
    handleFilter() {
      this.getOrderList();
    },
    handleDownload() {
      console.log("下载");
    },
    handleRowClick(row) {
      localStorage.setItem("order", JSON.stringify({ orderNo: row.orderNo }));
      this.$router.push(`order/${row.id}`);
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getOrderList();
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getOrderList();
    },
  },
  mounted() {
    this.getServiceNames();
    this.getOrderList();
  },
};
</script>

<style lang="scss" scoped>
.a-payment-container {
  position: relative;
  height: 100%;
  .a-download {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 1rem 0;
  }
}
</style>
